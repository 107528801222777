import React from 'react'
import Head from 'next/head'
import Link from 'next/link'

type Custom500Props = {
  message?: string,
  showRefresh?: boolean,
  refreshLink?: string,
}

const Custom500 = ({ message, showRefresh = false, refreshLink }: Custom500Props) => {
  return (
    <>
      <Head>
        <title>Request Error</title>
      </Head>
      <div className="items-center absolute inset-0 flex justify-center">
        <div>
          <div className="flex justify-center flex-col text-center max-w-75">
            <div className="mb-2">
              <h2 className="m-0 text-base font-medium text-gray-500">Something went wrong</h2>
            </div>
            <div className="mb-3">
              <p className="my-2 text-13 font-normal text-gray-400 leading-snug">
                { message ? message : 'There seems to be a problem with one of our servers. Rest assured our hardworking team is already looking into it and will be fixed soon.' }
              </p>
              <p className="pt-2 my-2 text-13 font-normal text-gray-400 leading-snug">
                Need help? Contact us at{' '}
                <Link href="mailto:support@magie.im" passHref>
                  <a target="_blank" className="text-blue-600 no-underline cursor-pointer focus:outline-none focus:ring-0">support@magpie.im</a>
                </Link>
              </p>
              {
                (showRefresh && refreshLink) &&
                <div className="mt-8">
                  <Link href={refreshLink} replace>
                    <a className="text-blue-600 no-underline cursor-pointer focus:outline-none focus:ring-0">
                      <div className="inline-flex items-center flex-row">
                        <div className="flex mr-1">
                          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" /></svg>
                        </div>
                        <span className="inline text-sm font-medium leading-5 whitespace-nowrap">
                          Return to the payment request
                        </span>
                      </div>
                    </a>
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Custom500