const getPaymentName = (method: string): string => {
  if (!method) return '';
  switch (method) {
    case 'card': return "Credit or debit card";
    case 'alipay': return "Alipay";
    case 'bdo': return "BDO Unibank";
    case 'bpi': return "Bank of the Philippine Islands";
    case 'coins': return "Coins.ph";
    case 'gcash': return "GCash";
    case 'grab': return "Grab Pay";
    case 'metrobank': return "Metrobank";
    case 'paymaya': return "PayMaya";
    case 'pnb': return "Philippine National Bank";
    case 'rcbc': return "Rizal Commercial Banking Corporation";
    case 'unionbank': return "Unionbank";
    case 'unionpay': return "UnionPay"
    case 'wechat': return "WeChat Pay"
    default: return method;
  }
}

const getCardBrandName = (brand: string): string => {
  if (!brand) return '';
  switch (brand) {
    case 'mastercard': return "Mastercard";
    case 'visa': return "Visa";
    case 'amex': return "American Express";
    case 'jcb': return "JCB"
    case 'discover': return "Discover"
    case 'unionpay': return "UnionPay"
    default: return brand;
  }
}

export default {
  getPaymentName,
  getCardBrandName,
}