const URIs = {
  CHARGE: '/charges/$1',
  REQUEST: 'requests/$1',
  REQUEST_PAY: 'requests/$1/pay',
  SESSIONS: '/sessions',
  SESSION: '/sessions/$1',
};

export const APPLICATION_KEY = 'lxp0IrmHQOANpUosuOCPfavWIFMvwD1n';

export default URIs;