import React from 'react'
import Head from 'next/head'

const Custom404 = () => {
  return (
    <>
      <Head>
        <title>Not Found</title>
      </Head>
      <div className="items-center absolute inset-0 flex justify-center">
        <div>
          <div className="flex justify-center flex-col text-center max-w-67.5">
            <div className="mb-2">
              <h2 className="m-0 text-base font-medium text-gray-500">Payment request  not found</h2>
            </div>
            <div className="mb-3">
              <p className="m-0 text-13 font-normal text-gray-400 mb-2 leading-snug">
                Please check the URL sent in the email/SMS or contact the merchant that sent you a payment request.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Custom404